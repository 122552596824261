<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: listRouteName }">
              {{ listTitle }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">{{ title }}</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="通路"
              label-for="merchant"
            >
              <b-form-select
                v-model="form.merchant_id"
                :options="merchants"
                :disabled="isFetchMerchants || isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.merchant_id.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="獎項名稱"
              label-for="name"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.name"
                :state="v$.form.name.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="獎項描述"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.description"
                :disabled="isShow"
                row="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="圖片"
              label-for="img_url"
            >
              <div class="image-selector">
                <div class="image" v-if="image || form.img_url">
                  <img
                    class="img-fluid"
                    :src="image != null ? image : form.img_url"
                  />
                  <button
                    v-if="!isShow"
                    class="btn btn-danger"
                    type="button"
                    @click="image = form.img_url = null"
                  >
                    <i class="fa fa-times m-0"></i>
                  </button>
                </div>
                <div class="selector" v-else>
                  <ImageSelector
                    @input="selectImage"
                    class="
                      border
                      h-100
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                    :aspectRatio="2 / 1"
                  >
                    <template #trigger="{ openFileSelector }">
                      <button
                        :disabled="!canClick"
                        class="btn s-trigger-btn"
                        @click="openFileSelector"
                      >
                        <i class="fa fa-plus"></i> 圖片
                      </button>
                    </template>
                    <template #content>
                      <small class="text-center mb-2">
                        建議尺寸 寬1400px 、 高 700px
                      </small>
                    </template>
                  </ImageSelector>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="獎項類型"
              label-for="type"
            >
              <b-form-radio-group
                v-model="form.type"
                :options="redeemCodeBundleTypes"
                :disabled="isShow"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="序號"
              label-for="type"
            >
              <b-form-radio-group
                v-model="form.redeemcode_type"
                :options="redeemCodeTypes"
                :disabled="isShow || isLinePointType"
              >
                <template #first>
                  <b-form-radio
                    v-model="form.redeemcode_type"
                    :value="consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.PACKAGE"
                  >
                    上傳序號包
                    <template
                      v-if="
                        form.redeemcode_type ===
                          consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.PACKAGE &&
                        !isShow
                      "
                    >
                      <b-button class="mx-2" v-b-modal.modal-upload>
                        上傳
                      </b-button>
                    </template>
                  </b-form-radio>
                </template>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="固定序號"
              label-for="name"
              v-if="form.redeemcode_type == 2"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.static_redeem_code"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="序號張數上限"
              label-for="name"
              v-if="form.redeemcode_type == 2"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.static_redeem_code_ceil_limit"
                placeholder="不填寫表示無限"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否顯示條碼"
              label-for="is_show_barcode"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.is_show_barcode"
                name="is_show_button"
                :value="true"
                :unchecked-value="false"
                :disabled="
                  isShow ||
                  form.redeemcode_type ==
                    consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE ||
                  isLinePointType
                "
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="條碼類型"
              label-for="barcode_type"
              v-if="form.is_show_barcode"
            >
              <b-form-select
                v-model="form.barcode_type"
                :options="barcodeTypes"
                disabled
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="領取方式"
            >
              <b-form-radio-group
                v-model="form.take_type"
                :state="v$.form.take_type.$error ? false : null"
                :disabled="isShow"
              >
                <span v-for="(takeType, index) in takeTypes" :key="index">
                  <b-form-radio
                    :id="`take-type-form-radio-${index}`"
                    :value="takeType.value"
                    :disabled="takeType.disabled"
                    v-b-tooltip.html.hover.bottom="{ customClass: 'text-left' }"
                    :title="takeType.tooltip"
                  >
                    {{ takeType.text }}
                  </b-form-radio>
                </span>
              </b-form-radio-group>
              <b-form-invalid-feedback :state="!v$.form.take_type.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="可否重複領券"
            >
              <b-form-radio-group
                v-model="form.reclaim_type"
                :state="v$.form.take_type.$error ? false : null"
                :disabled="isShow"
              >
                <!-- 這個等同於限領一次，畫面上就不用不可重複領券，但系統為了相容後端還是存在這個選項
                <b-form-radio :value="consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_DISABLED"> 不可重複領券 </b-form-radio>
                -->
                <b-form-radio :value="consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_LIMITED">
                  每人限領
                  <input class="reclaim-input" v-model="form.reclaim_times" type="number" min="1" :disabled="form.reclaim_type != consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_LIMITED"/>
                  次
                </b-form-radio>
                <b-form-radio :value="consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_UNLIMITED"> 不限次數</b-form-radio>
              </b-form-radio-group>
              <b-form-invalid-feedback :state="!v$.form.reclaim_type.$error">此欄位必填</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!v$.form.reclaim_times.$error">次數必須大於 0</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="領券次數超過上限時提示文字"
            >
              <b-form-input
                v-model="form.reclaim_message"
                :state="v$.form.reclaim_message.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.reclaim_message.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="領取期限"
            >
              <div
                class="
                  d-flex
                  flex-column flex-xl-row
                  align-items-start align-items-xl-center
                "
              >
                <datepicker
                  placeholder="Select Date"
                  v-model="start_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                  clear-button
                ></datepicker>
                <span class="mx-2">~</span>
                <datepicker
                  placeholder="Select Date"
                  v-model="end_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                  clear-button
                ></datepicker>
              </div>
              <b-form-invalid-feedback
                :state="!v$.start_at.beforeEndAt.$invalid"
              >
                結束時間必須大於起始時間
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="使用期限"
            >
              <b-form-radio-group
                v-model="form.dateline_type"
                :options="datelineTypes"
                :state="v$.form.dateline_type.$error ? false : null"
                :disabled="isShow"
              >
              </b-form-radio-group>
              <b-form-invalid-feedback :state="!v$.form.dateline_type.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              v-if="
                form.dateline_type ==
                consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.SAME_AS_TAKE_TIME
              "
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label=""
            >
              <div
                class="
                  d-flex
                  flex-column flex-xl-row
                  align-items-start align-items-xl-center
                "
              >
                <datepicker
                  placeholder="Select Date"
                  v-model="start_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="true"
                ></datepicker>
                <span class="mx-2">~</span>
                <datepicker
                  placeholder="Select Date"
                  v-model="end_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="true"
                ></datepicker>
              </div>
            </b-form-group>
            <b-form-group
              v-if="
                form.dateline_type ==
                consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.CUSTOM
              "
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label=""
            >
              <div
                class="
                  d-flex
                  flex-column flex-xl-row
                  align-items-start align-items-xl-center
                "
              >
                <datepicker
                  placeholder="Select Date"
                  v-model="used_start_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                ></datepicker>
                <span class="mx-2">~</span>
                <datepicker
                  placeholder="Select Date"
                  v-model="used_end_at"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabled="isShow"
                  :disabledDates="disabledDates"
                ></datepicker>
              </div>
            </b-form-group>
            <b-form-group
              v-if="
                form.dateline_type ==
                consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.BY_TAKE_DATE
              "
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="領取後幾日可使用（0 為當日）"
            >
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                v-model="form.start_days_after_take"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="
                form.dateline_type ==
                consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.BY_TAKE_DATE
              "
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="領取後幾日內有效（0 為當日需使用完畢）"
            >
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                v-model="form.end_days_after_take"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="核銷設定"
            >
              <b-form-radio-group
                v-model="form.confirm_type"
                :options="confirmTypes"
                :state="v$.form.confirm_type.$error ? false : null"
                :disabled="isShow || isLinePointType"
              >
              </b-form-radio-group>
              <b-form-invalid-feedback :state="!v$.form.confirm_type.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否顯示獎項按鈕"
              label-for="is_show_button"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.is_show_button"
                name="is_show_button"
                :value="true"
                :unchecked-value="false"
                :disabled="isShow || isLinePointType"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="按鈕文字"
              label-for="button_text"
              v-if="form.is_show_button"
            >
              <b-form-input
                id="button_text"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.button_text"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="按鈕連結"
              label-for="button_url"
              v-if="form.is_show_button && !isLinePointType"
            >
              <b-form-input
                id="button_url"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="form.button_url"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否以外部瀏覽器開啟"
              label-for="button_url_external"
              v-if="form.is_show_button"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.button_url_external"
                name="button_url_external"
                :value="true"
                :unchecked-value="false"
                :disabled="isShow || isLinePointType"
              ></b-form-checkbox>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel">{{
          isShow ? "返回" : "取消"
        }}</b-button>
        <b-overlay
          v-if="!isShow"
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit">
            {{ isEdit ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
    <b-modal id="modal-upload" title="上傳優惠券">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <div class="my-3">已選擇檔案 : {{ file ? file.name : "" }}</div>
          <b-form-file class="mb-3" plain v-model="file"></b-form-file>
          <b-button
            size="sm"
            variant="outline-primary"
            href="/excel/event_redeem_code_list.xlsx"
            target="_blank"
            ><i class="fa fa-file-text"></i>下載範例檔案
          </b-button>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="resetFile"
        >
          取消
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="cancel()">
          確認
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, minValue } from "@vuelidate/validators";
import { format, isBefore, set, subDays } from "date-fns";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import redeemCodeBundleApi from "../../../apis/redeemCodeBundle";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import myConsoleMixin from "@/mixins/Dashboard/RedeemCodeBundle/myConsole";
import ImageSelector from "@/components/ImageSelector";

export default {
  components: { Datepicker, ImageSelector },
  mixins: [myConsoleMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    let redeemCodeBundleTypes = [
      ...Object.keys(consts.REDEEM_CODE_BUNDLE_TYPES).map((key) => {
        const value = consts.REDEEM_CODE_BUNDLE_TYPES[key];
        return {
          text: consts.REDEEM_CODE_BUNDLE_TYPE_TRANSLATE[value],
          value,
        };
      }),
    ];
    redeemCodeBundleTypes = redeemCodeBundleTypes.filter(
      (item) => item.value != consts.REDEEM_CODE_BUNDLE_TYPES.COUPON
    );

    return {
      zh,
      isLoading: false,
      isSubmmiting: false,
      isFetchMerchants: false,
      merchants: [],
      disabledDates: { to: subDays(new Date(), 1) },
      form: {
        merchant_id: null,
        name: "",
        type: null,
        is_show_barcode: false,
        barcode_type: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_128,
        is_show_button: false,
        static_redeem_code_ceil_limit: null,
        redeemcode_type: null,
        reclaim_type: consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_LIMITED,
        reclaim_times: 1,
        reclaim_message: null,
      },
      start_at: new Date(),
      end_at: new Date(),
      redeemCodeBundleTypes,
      redeemCodeTypes: [
        {
          text: "固定序號",
          value: consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.FIXED,
        },
        {
          text: "不使用序號",
          value: consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE,
        },
      ],
      datelineTypes: [
        {
          text: "與領取區間相同",
          value: consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.SAME_AS_TAKE_TIME,
        },
        {
          text: "自定日期區間",
          value: consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.CUSTOM,
        },
        {
          text: "依日期區間推算",
          value: consts.REDEEM_CODE_BUNDLE_DATELINE_TYPES.BY_TAKE_DATE,
        },
      ],
      confirmTypes: [
        {text: '使用後自動核銷（五分鐘內需使用）', value: consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.AUTO},
        {text: '使用後手動核銷（若未手動核銷，可重複使用）', value: consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.MANUAL},
        {text: '無核銷按鈕', value: consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.NONE},
        {text: '輸入店號核銷', value: consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.BRANCH_CODE},
      ],
      used_start_at: new Date(),
      used_end_at: new Date(),
      barcodeTypes: [
        {
          text: "Code 128",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_128,
        },
        {
          text: "Code 39",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_39,
        },
        {
          text: "Code 39 (顯示檢查碼)",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_39_WITH_CHECKSUM,
        },
        { text: "PZN", value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.PZN },
        {
          text: "EAN-13",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.EAN_13,
        },
        { text: "EAN-8", value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.EAN_8 },
        { text: "JAN", value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.JAN }, // another name for the EAN-13
        {
          text: "ISBN-13",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISBN_13,
        },
        {
          text: "ISBN-10",
          value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISBN_10,
        },
        { text: "ISSN", value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISSN },
        { text: "UPC-A", value: consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.UPC_A },
      ],
      consts,
      showLoadingUpload: false,
      file: null,
      image: null,
      canClick: true,
      canUpload: true,
    };
  },
  validations() {
    return {
      form: {
        merchant_id: { required },
        name: { required },
        take_type: { required },
        dateline_type: { required },
        confirm_type: { required },
        reclaim_type: { required },
        reclaim_times: { requiredIf:  requiredIf(this.form.reclaim_type == consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_LIMITED), minValue: minValue(1)},
        reclaim_message: {}
      },
      start_at: {
        beforeEndAt: () => {
          if (!this.start_at || !this.end_at) {
            return true;
          }

          const startDate = set(new Date(this.start_at), {
            hours: 0,
            minutes: 0,
          });
          const endDate = set(new Date(this.end_at), {
            hours: 23,
            minutes: 59,
          });
          return isBefore(startDate, endDate);
        },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('route', ['queryParams']),
    redeemCodeBundleId() {
      return this.$route.params.redeemCodeBundleId;
    },
    isEdit() {
      return ["RedeemCodeBundleEdit", "MyConsoleRedeemCodeBundleEdit"].includes(
        this.$route.name
      );
    },
    isShow() {
      return [
        "RedeemCodeBundleDetail",
        "MyConsoleRedeemCodeBundleDetail",
      ].includes(this.$route.name);
    },
    title() {
      switch (true) {
        case this.isEdit:
          return "優惠券編輯";
        case this.isShow:
          return "檢視優惠券";
        default:
          return "新增優惠券";
      }
    },
    takeTypes() {
      const disabled =
        this.form.redeemcode_type ==
        consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE;
      return [
        {
          text: "輸入優惠卷代碼",
          value: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.INPUT_CODE,
          disabled,
          tooltip: "可透過輸入代碼<br>或連結領券",
        },
        {
          text: "直接領取",
          value: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.DIRECT,
          tooltip: "僅透過連結領券",
        },
        {
          text: "點數兌換",
          value: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.POINT,
          tooltip: "以點數兌換領<br>券，須設定相關<br>點數兌換規則",
        },
        {
          text: "系統派發",
          value: consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.SYSTEM_ASSIGN,
          tooltip: "券由系統直接發<br>券",
        },
      ];
    },
    isLinePointType() {
      return this.form.type == consts.REDEEM_CODE_BUNDLE_TYPES.LINE_POINT;
    },
  },
  watch: {
    "form.redeemcode_type"(val) {
      if (val === consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE) {
        if (this.form.is_show_barcode) {
          this.form.is_show_barcode = false;
        }
        if (
          this.form.take_type == consts.REDEEM_CODE_BUNDLE_TAKE_TYPES.INPUT_CODE
        ) {
          this.form.take_type = null;
        }
      }
    },
    "form.type"(val) {
      if (val == consts.REDEEM_CODE_BUNDLE_TYPES.LINE_POINT) {
        this.form.redeemcode_type =
          consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.PACKAGE;
        this.form.confirm_type = consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.NONE;
        this.form.is_show_button = true;
        this.form.button_url =
          "https://points.line.me/pointcode?pincode={$code}#/pointcode/form";
        this.form.is_show_barcode = false;
        this.form.button_url_external = false;
      }
    },
  },
  mounted() {
    if (
      (!this.fromMyConsole &&
        !this.checkPermission([consts.REDEEM_CODE_LIST_CREATE])) ||
      (this.fromMyConsole &&
        !this.checkPermission([consts.MY_REDEEM_CODE_LIST_VIEW]))
    ) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: this.listRouteName, query: this.queryParams});
        });
      return;
    }

    if (this.redeemCodeBundleId) {
      this.fetchRedeemCodeBundle();
    }
    this.getMerchants();
  },
  methods: {
    async fetchRedeemCodeBundle() {
      try {
        this.isLoading = true;
        const { data } = await redeemCodeBundleApi.getRedeemCodeBundle(
          this.redeemCodeBundleId
        );
        const {
          id,
          merchant_id,
          name,
          description,
          type,
          redeemcode_type,
          take_type,
          dateline_type,
          used_start_at,
          used_end_at,
          barcode_type,
          start_at,
          end_at,
          start_days_after_take,
          end_days_after_take,
          confirm_type,
          is_show_button,
          is_show_barcode,
          button_text,
          button_url,
          button_url_external,
          img_url,
          static_redeem_code,
          static_redeem_code_ceil_limit,
          reclaim_type,
          reclaim_times,
          reclaim_message,
        } = data.data;

        if (this.isShow || this.isEdit) {
          this.form = {
            ...this.form,
          };
          this.form = {
            id,
            merchant_id,
            name,
            description,
            type,
            redeemcode_type,
            take_type,
            dateline_type,
            barcode_type,
            start_days_after_take,
            end_days_after_take,
            confirm_type,
            is_show_button,
            is_show_barcode,
            button_text,
            button_url,
            button_url_external,
            img_url,
            static_redeem_code,
            static_redeem_code_ceil_limit,
            reclaim_type,
            reclaim_times,
            reclaim_message,
          };
          this.start_at = start_at && new Date(start_at)
          this.end_at = end_at && new Date(end_at)
          this.used_start_at = used_start_at && new Date(used_start_at)
          this.used_end_at = used_end_at && new Date(used_end_at)
          if (this.form.reclaim_type == consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_DISABLED) {
            this.form.reclaim_type = consts.REDEEM_CODE_BUNDLE_RECLAIM_TYPES.RECLAIM_TYPE_LIMITED
            this.form.reclaim_times = 1
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        if (this.image) {
          await this.uploadImage(this.image);
        }
        this.handleFormatDates();
        await redeemCodeBundleApi.updateRedeemCodeBundle(
          this.redeemCodeBundleId,
          {
            ...this.form,
          }
        );
        if (
          this.file &&
          this.form.redeemcode_type ==
            this.consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.PACKAGE
        ) {
          let result = await this.uploadRedeemCodeData(this.redeemCodeBundleId);
          if (!result) {
            this.isSubmmiting = false;
            return;
          }
        }
        this.showSuccessPopup("修改成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    handleFormatDates() {
      this.form.start_at = this.doHandleFormatDates(this.start_at, 0, 0, 0);
      this.form.end_at = this.doHandleFormatDates(this.end_at, 23, 59, 59);
      this.form.used_start_at = this.doHandleFormatDates(
        this.used_start_at,
        0,
        0,
        0
      );
      this.form.used_end_at = this.doHandleFormatDates(
        this.used_end_at,
        23,
        59,
        59
      );
    },
    doHandleFormatDates(datetime, hours, minutes, seconds) {
      if (!datetime) return null;

      return format(
        set(new Date(datetime), {
          hours,
          minutes,
          seconds,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        if (this.image) {
          await this.uploadImage(this.image);
        }
        this.handleFormatDates();
        const { data } = await redeemCodeBundleApi.createRedeemCodeBundle({
          fromMyConsole: this.fromMyConsole,
          ...this.form,
        });
        if (
          this.file &&
          this.form.redeemcode_type ==
            this.consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.PACKAGE
        ) {
          console.log("create file");
          console.log(data);
          let result = await this.uploadRedeemCodeData(data.data.id);
          if (!result) {
            this.isSubmmiting = false;
            return;
          }
        }
        this.showSuccessPopup("新增成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];

      const lineMerchant = data.data.filter((merchant) => {
        return merchant.type == "LINE";
      });

      if (lineMerchant && !this.form.merchant_id) {
        this.form.merchant_id = lineMerchant[0].id;
      }
    },
    handleCancel() {
      this.$router.push({ name: this.listRouteName, query: this.queryParams });
    },
    showSuccessPopup(message) {
      this.$swal
        .fire({
          type: "success",
          text: message,
        })
        .then(() => {
          this.$router.push({ name: this.listRouteName, query: this.queryParams});
        });
    },
    showErrorMessage(error) {
      console.error(error);
      const { status, data } = error.response;

      let html = "未知錯誤";
      if (status && status === 422 && data.message) {
        html = Object.values(data.message)
          .map((m) => m[0])
          .join("<br/>");
      } else if (data.message) {
        html = data.message;
      }

      this.$swal.fire({
        type: "error",
        html,
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },

    resetFile() {
      this.file = null;
      this.$bvModal.hide("modal-upload");
    },

    async uploadRedeemCodeData(id) {
      try {
        let formData = new FormData();
        formData.append("file", this.file);
        await redeemCodeBundleApi.imoprtRedeemCode(id, formData);
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
        return false;
      }
      return true;
    },
    selectImage(image) {
      this.image = image;
    },
    async uploadImage(imageBase64) {
      try {
        this.canClick = false;
        const response = await redeemCodeBundleApi.uploadImageByBase64(
          imageBase64
        );
        this.form.img_url = response.data.img_url;
      } catch (err) {
        throw new Error("上傳圖片失敗");
      } finally {
        this.canClick = true;
      }
    },
  },
};
</script>

<style scoped>
.reclaim-input {
  width: 5rem;
  border: 1px solid;
}
.text-left::v-deep .tooltip-inner {
  text-align: left;
}

.image-selector {
  .image {
    position: relative;
    width: 30%;
    max-width: 200px;
    max-height: 100px;
    button {
      position: absolute;
      top: -1rem;
      right: -1rem;
    }
  }
  .selector {
    width: 200px;
    height: 100px;
  }
}
</style>
